import request from '@/utils/axios-request'

// 查询 controller-查询公司列表
export const apiCompanyList = (data: any) => {
  return request({
    url: '/search/company-list',
    method: 'post',
    data
  })
}

// 查询 controller-查询风险公司列表
export const apiRiskyCompanyList = (params: any) => {
  return request({
    url: '/search/risky-company-list',
    method: 'get',
    params
  })
}
